import React from 'react'

export default function Contact() {
  return (
    <div className="contact mt-5" id="contact">
      <div className="container pt-3 pb-5 position-relative">
        <img src={require("../../../assets/images/dots.png")} className="dots" />
        <h2 className="text-white mt-5 mb-3">GET IN TOUCH</h2>
        <div className="row">
          <div className="col-md-4">
            <form>
              <div class="form-group">
                <label for="name" className="text-white">Name</label>
                <input type="text" class="form-control rounded-0" id="name" />
              </div>
              <div class="form-group">
                <label for="email" className="text-white">Email address</label>
                <input type="email" class="form-control rounded-0" id="email" />
              </div>
              <div class="form-group">
                <label for="email" className="text-white">Email address</label>
                <textarea type="email" class="form-control rounded-0" id="email" placeholder="Write something..." />
              </div>
              <button type="submit" class="btn btn-primary btn-sm px-5 mt-2">Submit</button>
            </form>
          </div>
          <div className="col-md-8 info mt-5">
            <Item
              image={require('../../../assets/images/about-item-1.png')}
              title="PHONE"
              value="+63 9028374621"
            />
            <Item
              image={require('../../../assets/images/about-item-2.png')}
              title="EMAIL"
              value="pngteksolutions@gmail.com"
            />
            <Item
              image={require('../../../assets/images/about-item-3.png')}
              title="FACEBOOK"
              value="PNGTek Solutions"
            />
          </div>
        </div>
      </div>
    </div>
  )
}

const Item = ({ image, title, value }) => {
  return (
    <div className="mt-3 item">
      <img src={image} />
      <h4 className="text-white mt-4">{title}</h4>
      <p className="text-white">{value}</p>
    </div>

  )
}
