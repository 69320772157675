import React from 'react'

export default function Footer() {
  return (
    <div className="footer">
      <div className="container pt-3 pb-2">
        <img src={require('../../../assets/images/dots.png')} className="dots" />
        <img src={require('../../../assets/images/logo-white.png')} className="mt-4 mb-3 mx-auto d-block white-logo" />
        <div className="row ">
          <div className="offset-sm-2 col-md-8 info">
            <Item
              image={require('../../../assets/images/about-item-1.png')}
              title="PHONE"
              value="+63 9028374621"
            />
            <Item
              image={require('../../../assets/images/about-item-2.png')}
              title="EMAIL"
              value="pngteksolutions@gmail.com"
            />
            <Item
              image={require('../../../assets/images/about-item-3.png')}
              title="FACEBOOK"
              value="PNGTek Solutions"
            />
          </div>
        </div>
        <p className="text-center text-white mt-5">@ PNGTek Solutions 2020</p>
      </div>
    </div >
  )
}

const Item = ({ image, title, value }) => {
  return (
    <div className="mt-3 item">
      <img src={image} />
      <h4 className="text-white mt-4">{title}</h4>
      <p className="text-white">{value}</p>
    </div>

  )
}
