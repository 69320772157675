import React from 'react'

export default function WhatWeDo() {
  return (
    <div className="what-we-do" id="what-we-do">
      <div className="container pt-3 pb-5">
        <img src={require('../../../assets/images/dots.png')} className="dots" />
        <h2 className="text-white mt-5 mb-3">WHAT WE DO</h2>
        <div className="row">
          <Item
            image={require('../../../assets/images/item1.png')}
            title="MOBILE APP"
            description="Lorem ipsum dolor sit amet, consectetur
              adipiscing elit. Ac, massa pellentesque
              magna porta."
          />
          <Item
            image={require('../../../assets/images/item2.png')}
            title="WEB APP"
            description="Lorem ipsum dolor sit amet, consectetur
            adipiscing elit. Ac, massa pellentesque
            magna porta."
          />
          <Item
            image={require('../../../assets/images/item3.png')}
            title="DESIGN & PROTOTYPE"
            description="Lorem ipsum dolor sit amet, consectetur
            adipiscing elit. Ac, massa pellentesque
            magna porta."
          />
          <Item
            image={require('../../../assets/images/item4.png')}
            title="SECURITY & PRIVACY"
            description="Lorem ipsum dolor sit amet, consectetur
            adipiscing elit. Ac, massa pellentesque
            magna porta."
          />
          <Item
            image={require('../../../assets/images/item5.png')}
            title="PROJECT MANAGEMENT"
            description="Lorem ipsum dolor sit amet, consectetur
            adipiscing elit. Ac, massa pellentesque
            magna porta."
          />
          <Item
            image={require('../../../assets/images/item6.png')}
            title="API DEVELOPMENT & INTEEGRRATION"
            description="Lorem ipsum dolor sit amet, consectetur
            adipiscing elit. Ac, massa pellentesque
            magna porta."
          />
        </div>
      </div>
    </div >
  )
}

const Item = ({ image, title, description }) => {
  return (
    <div className="col-sm-4">
      <div className="item my-4 px-3">
        <img src={image} />
        <span className="title mt-3 font-weight-bold">{title}</span>
        <span className="description">
          {description}
        </span>
      </div>
    </div>)
}
