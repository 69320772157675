import React from 'react'

export default function Navbar() {
  return (
    <nav class="navbar sticky-top navbar-expand-lg navbar-light  ">
      <div className="container d-flex justify-content-between">
        <a class="navbar-brand" href="#">
          <img src={require('../../../assets/images/logo_name.png')} height="28" class="d-inline-block align-top" alt="" />
        </a>
        <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
          <span class="navbar-toggler-icon"></span>
        </button>

        <div class="collapse navbar-collapse navbar-links" id="navbarSupportedContent">
          <ul class="navbar-nav">
            <li class="nav-item active">
              <a class="nav-link" href="#home">HOME</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" href="#what-we-do">WHAT WE DO</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" href="#about">ABOUT US</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" href="#contact">CONTACT</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" href="#our-team">OUR TEAM</a>
            </li>
            <li class="nav-item">
              <a class="btn btn-sm btn-primary rounded-0 px-3 get-started-btn" href="#">GET STARTED</a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  )
}
