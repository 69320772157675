import React, { Component } from 'react'
import { connect } from 'react-redux'
import AboutUs from './components/AboutUs'
import Banner from './components/Banner'
import Contact from './components/Contact'
import Footer from './components/Footer'
import Navbar from './components/Navbar'
import OurTeam from './components/OurTeam'
import WhatWeDo from './components/WhatWeDo'

class Home extends Component {
  render() {
    return (
      <div >
        <Navbar />
        <Banner />
        <WhatWeDo />
        <AboutUs />
        <Contact />
        <OurTeam />
        <Footer />
      </div>
    )
  }
}

const mapStateToProps = (state) => ({

})

const mapDispatchToProps = {

}

export default connect(mapStateToProps, mapDispatchToProps)(Home)
