import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Route, Switch, BrowserRouter, Redirect } from 'react-router-dom';
import Page404 from '../components/errors/page_404';
import Home from '../pages/home';

export class Routes extends Component {
  render() {
    return (
      <BrowserRouter>
        <Switch>
          <Route path='/404' component={Page404} />
          <Route path='/' exact component={Home} />
          <Redirect to='/' />
        </Switch>
      </BrowserRouter>
    );
  }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Routes);
