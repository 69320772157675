import React from 'react'

export default function AboutUs() {
  return (
    <div className="about pb-5" id="about">
      <div className="container pt-3">
        <h2 className="font-weight-bold mt-5 title mb-5">ABOUT US</h2>
        <div className="row">
          <div className="col-sm-5">
            <img src={require("../../../assets/images/about-image.png")} className="w-100 mt03" />
          </div>
          <div className="col-sm-6 mt-5 offset-1">
            <h3 className="font-weight-bold">WHO ARE WE</h3>
            <div className="my-3 about-divider" />
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. A, egestas vel morbi
              lobortis morbi eget leo. Diam, justo, id adipiscing elit.
              Montes, viverra dolor pharetra ornare etiam turpis non in diam.
              Malesuada pharetra, nam aenean pellentesque id in nullam ipsum laoreet.
            </p>

            <h3 className="font-weight-bold mt-5">OUR STORY</h3>
            <div className="my-3 about-divider" />
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. A, egestas vel morbi
              lobortis morbi eget leo. Diam, justo, id adipiscing elit.
              Montes, viverra dolor pharetra ornare etiam turpis non in diam.
              Malesuada pharetra, nam aenean pellentesque id in nullam ipsum laoreet.
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}
