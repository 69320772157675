import React from 'react'

export default function OurTeam() {
  return (
    <div className="our-team pb-5" id="our-team">
      <div className="container pt-3">
        <h2 className="font-weight-bold mt-5 title ">OUR TEAM</h2>
        <p className="mx-md-5 px-md-5 d-block mx-auto mb-5 mt-3 text-center">Lorem ipsum dolor sit amet, consectetur adipiscing elit. A, egestas vel morbi lobortis morbi eget leo. Diam, justo, id adipiscing eli. Montes, viverra dolor pharetra ornare etiam turpis non in diam. </p>

        <div className="row">
          <Item image={require("../../../assets/images/team-1.png")} className="w-100 mt-3" name="Laurence Bautista" position="FULL STACK DEVELOPER" />
          <Item image={require("../../../assets/images/team-2.png")} className="w-100 mt-3" name="Jayhiel Vhon Lopez" position="MOBILE DEVELOPER" />
          <Item image={require("../../../assets/images/team-3.png")} className="w-100 mt-3" name="Jemima Prado" position="UI/UX GRAPHIC DESIGNER" />
        </div>
      </div>
    </div>
  )
}

const Item = ({ image, name, position }) => {
  return (
    <div className="col-sm-4 px-5 pb-5">
      <img src={image} className="w-100 mt-3" />
      <h5 className="text-center mt-4">{name}</h5>
      <p className="text-center">{position}</p>
    </div>
  )
}
