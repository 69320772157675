import React from 'react'

export default function Banner() {
  return (
    <div className="container banner" id="home">
      <div className="row">
        <div className="col-sm-7 mt-5 pt-md-5">
          <h1 className="mt-md-5 pt-md-5">
            WE <span className="text-orange">DESIGN</span> & <span className="text-orange">BUILD</span> TO
            MAKE YOUR LIFE <span className="text-orange">EASIER</span>.
          </h1>
          <span>The Perfect and Innovative Software at your service!</span>
          <div className="mt-3 banner-divider" />
        </div>
        <div className="col-sm-5 py-5">
          <img src={require('../../../assets/images/banner-image.png')} />
        </div>
      </div>
    </div>
  )
}
